import { Injectable } from '@angular/core';
import { Http, Headers, Response} from '@angular/http';
import { map } from 'rxjs/operators';
import { Urls } from '../urls';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
	url : string;
  constructor(
  	private _http : Http
  	) {
  	this.url = Urls.local;
  	 }

  	 login(user, pass){
  	 	return this._http.get(this.url+'logueo/'+user+'/'+pass).pipe(map(res=>res.json()));
  	 }
}
