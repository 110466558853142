import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HttpModule } from '@angular/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule} from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { OrderModule } from 'ngx-order-pipe';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import localeEsCo from '@angular/common/locales/es-CO';

registerLocaleData(localeEsCo, 'es');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarioComponent } from './componentes/calendario/calendario.component';
import { MenuPrincipalComponent } from './componentes/menu-principal/menu-principal.component';
import { LoginComponent } from './componentes/login/login.component';
import { GruposComponent } from './componentes/grupos/grupos.component';
import { EventosComponent } from './componentes/eventos/eventos.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarioAsesorComponent } from './componentes/calendario-asesor/calendario-asesor.component';

@NgModule({
  declarations: [
    AppComponent,
    CalendarioComponent,
    MenuPrincipalComponent,
    LoginComponent,
    GruposComponent,
    EventosComponent,
    CalendarioAsesorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MDBBootstrapModule.forRoot(),
    HttpModule,
    SweetAlert2Module.forRoot(),
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    OrderModule,
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  providers: [
  { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
