import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../servicios/login.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { Router, Route} from '@angular/router';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	@ViewChild('error') errorL : SwalComponent;

	logForm : FormGroup;
	submited : boolean = false;
	loading : boolean = false;
	texto_btn : string = 'Iniciar Sésion';
  constructor(
  	private fb : FormBuilder,
  	private _lS : LoginService,
  	private route : Router
  	) { 
  	this.logForm = this.fb.group({
  		user : ['',Validators.required],
  		pass : ['', Validators.required]
  	});
  }

  ngOnInit() {
  }

  get lF(){ return this.logForm.controls;}

  log(){
  	this.submited = true;
  	if(this.logForm.valid){

  	this.loading = true;
  	this.texto_btn = 'Cargando...'

  		this._lS.login(this.lF.user.value, this.lF.pass.value).subscribe(res=>{
  			console.log(res);
  			if(res.code == 200 && res.data != null){

  				let data = {
  					allow : res.data.permisos_id,
  					sede : res.data.sede,
  					marca : res.data.marca
  				}

  				localStorage.setItem('data', JSON.stringify(data));
  				setTimeout(()=>{
					this.route.navigate(['/menu']);
  				},100);
  				
  			}else{
  				this.errorL.show();
  				this.loading = false;
  				this.texto_btn = 'Iniciar Sésion';
  			}
  		},error=>{
  			this.loading = false;
  			this.texto_btn = 'Iniciar Sésion';
  			console.log(error);
  		});
  	}
  }

}
