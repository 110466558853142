import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CalendarioComponent } from './componentes/calendario/calendario.component';
import { LoginComponent } from './componentes/login/login.component';
import { MenuPrincipalComponent } from './componentes/menu-principal/menu-principal.component';
import { GruposComponent } from './componentes/grupos/grupos.component';
import { EventosComponent } from './componentes/eventos/eventos.component';
import { CalendarioAsesorComponent } from './componentes/calendario-asesor/calendario-asesor.component';

const routes: Routes = [
{path : '', component: LoginComponent},
{path : 'calendario-asesor', component : CalendarioAsesorComponent},
{path : 'login', component : LoginComponent},
{path : 'menu', component : MenuPrincipalComponent},
{path : 'calendario', component : CalendarioComponent},
{path : 'grupos', component : GruposComponent},
{path : 'eventos', component : EventosComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
