import { Component, OnInit, ViewChild } from '@angular/core';
import { GruposService } from '../../servicios/grupos.service';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Urls } from '../../urls';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.css']
})
export class GruposComponent implements OnInit {

	@ViewChild('success') successAlert : SwalComponent;
	@ViewChild('success2') successAlert2 : SwalComponent;

	public color : string = '#1889e9';
	miembros : Array<any>;
	grupos : Array<any>;
	fotos : string;

  datos : any;

	formGrupo : FormGroup;
	submited : boolean = false;

	loading : boolean = false;

  constructor(
  	private _gS : GruposService,
  	private fb : FormBuilder
  	) { 
  	this.miembros = [];
  	this.fotos = Urls.fotos_ext;
  	this.grupos = [];
  	this.formGrupo = this.fb.group({
  		nombre : ['', Validators.required],
  		sede : [ ,Validators.required],
  		marca : [ , Validators.required]
  	});

    this.datos = JSON.parse(localStorage.getItem('data'));

  }

  ngOnInit() {
  	this.getAsesores();
  	this.getGrupos();
  }

  getAsesores(){
    console.log(this.datos.sede);
  	this.loading = true;
  	this._gS.getAsesores(this.datos.marca, this.datos.sede).subscribe(res=>{
      console.log(res);
  		if(res.code == 200){
  			for (var i = 0; i < res.data.length; ++i) {
  				this.miembros.push(res.data[i]);
  			}
  			this.loading = false;
  		}
  	},error=>{
  		console.log(error);
  		this.loading = false;
  	});
  }

  getGrupos(){
  	this._gS.getGrupos(this.datos.marca, this.datos.sede).subscribe(res=>{
  		if(res.code == 200){
  			this.grupos = [];
  			for (var i = 0; i < res.data.length; ++i) {
  				this.grupos.push(res.data[i]);
  			}
  		}
  		console.log(this.miembros);
  	},error=>{
  		console.log(error);
  	});
  }

  get fg(){ return this.formGrupo.controls; }

  setGrupo(){

  	this.submited = true;

  	if(this.formGrupo.valid){
  	let datos = {
  		nombre : this.fg.nombre.value,
  		sede : this.fg.sede.value,
  		marca : this.fg.marca.value,
  		color : this.color
  	}

  	this._gS.setGrupo(datos).subscribe(res=>{
  		console.log(res);
  		if(res.code == 200){
  			this.submited = false;
  			this.successAlert.show();
  			this.formGrupo.reset();
  			this.getGrupos();

  		}
  	},error=>{
  		console.log(error);
  	});
  	}

  }

  asignarGrupo(idg, cc, nombre){
  	this.loading = true;
  	let data = {
  		cc : cc,
  		nombre : nombre,
  		idg : idg
  	}

  	this._gS.setMiembro(data).subscribe(res=>{
  		this.loading = false;
  		if(res.code == 200){
  			console.log(res.message);
  			this.successAlert2.show();
  		}else{
        console.log(res.message);
      }
  		
  	},error=>{
  		this.loading = false;
  		console.log(error);
  	});

  }

//12256413
}
