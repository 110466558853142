import { Injectable } from '@angular/core';
import { Http, Headers, Response} from '@angular/http';
import { map } from 'rxjs/operators';
import { Urls } from '../urls';
@Injectable({
  providedIn: 'root'
})
export class GruposService {

	url : string;

  constructor(
  	private _http : Http
  	) {
  	this.url = Urls.local
  	 }


  getAsesores(marca, sede){
  	return this._http.get(this.url+'get-asesores/'+marca+'/'+sede).pipe(map(res=>res.json()));
  }

  getGrupos(marca, sede){
    return this._http.get(this.url+'get-grupos/'+marca+'/'+sede).pipe(map(res=>res.json()));
  }

  getGruposAntiguos(mes){
    return this._http.get(this.url+'get-grupos-antiguos/'+mes).pipe(map(res=>res.json()));
  }

  deleteEvento(id){
    return this._http.get(this.url+'delete-evento/'+id).pipe(map(res=>res.json()));
  }

  deleteActividad(id){
    return this._http.get(this.url+'delete-actividad/'+id).pipe(map(res=>res.json()));
  }

  getAllGrupos(){
    return this._http.get(this.url+'get-grupos').pipe(map(res=>res.json()));
  }

  getMiembros(marca, sede){
    return this._http.get(this.url+'get-miembros/'+marca+'/'+sede).pipe(map(res=>res.json()));
  }

  getEventos(){
    return this._http.get(this.url+'get-eventos').pipe(map(res=>res.json()));
  }

  getCalendario(fecha){
    return this._http.get(this.url+'get-calendario/'+fecha).pipe(map(res=>res.json()));
  }

  getCalendarioEvento(id){
    return this._http.get(this.url+'get-calendario-evento/'+id).pipe(map(res=>res.json()));
  }


  getEventosGrupo(grupo){
    return this._http.get(this.url+'get-eventos-grupos/'+grupo).pipe(map(res=>res.json()));
  }

  setGrupo(data){

    let json = JSON.stringify(data);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});
    return this._http.post(this.url+'set-grupo', params, {headers : headers}).pipe(map(res=>res.json()));
  }

  setMiembro(data){

    let json = JSON.stringify(data);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});
    return this._http.post(this.url+'set-miembro', params, {headers : headers}).pipe(map(res=>res.json()));
  }

  setEvento(data){

    let json = JSON.stringify(data);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});
    return this._http.post(this.url+'set-evento', params, {headers : headers}).pipe(map(res=>res.json()));
  }

  setEventoCalendario(data){

    let json = JSON.stringify(data);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});
    return this._http.post(this.url+'set-evento-calendario', params, {headers : headers}).pipe(map(res=>res.json()));
  }

  updateEventoCalendario(data){

    let json = JSON.stringify(data);
    let params = 'json='+json;
    let headers = new Headers({'Content-Type':'application/x-www-form-urlencoded'});
    return this._http.post(this.url+'update-evento-calendario', params, {headers : headers}).pipe(map(res=>res.json()));
  }

}
