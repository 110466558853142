import { Component, OnInit, ViewChild } from '@angular/core';
import { GruposService } from '../../servicios/grupos.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import {NgbDateStruct, NgbCalendar, NgbModal, NgbDate} from '@ng-bootstrap/ng-bootstrap';

declare var $ : any;

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {
	@ViewChild('success') successAlert : SwalComponent;
	@ViewChild('del') delAlert : SwalComponent;
  @ViewChild('error') errorAlert : SwalComponent;
  @ViewChild('asignacion') asignacionAlert : SwalComponent;
  @ViewChild('actividad') actividadAlert : SwalComponent;
	
  hoveredDate: NgbDate;

  fromDate: NgbDate;
  toDate: NgbDate;

	public color : string = '#1889e9';
	
	formEvent : FormGroup;
  formCalendar : FormGroup;

	submited : boolean = false;
  submitedC : boolean = false;
	loading : boolean = true;

  grupos : Array<any>;
  eventos : Array<any>;
  miembros : Array<any>;
  datos : any;

  titulo : string = '';
  texto : string = '';
  tipo : string = '';
  public isCollapsed = true;

	event_select : string;
  	constructor(
  	private _gS : GruposService,
  	private fb : FormBuilder,
    private calendar: NgbCalendar,
    private modalService: NgbModal
  	) {

  	this.formEvent = this.fb.group({
  		nombre : ['',Validators.required],
  		siglas : ['', Validators.required]
  	});

    this.formCalendar = this.fb.group({
      localidad : ['', Validators.required],
      ciudad : ['', Validators.required],
      grupo_id : [ , Validators.required],
      evento_id : [, Validators.required],
      destacado : [false]      
    });

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

  	this.eventos = [];
    this.grupos = [];
    this.miembros = [];
  	this.event_select = '';

    this.datos = JSON.parse(localStorage.getItem('data'));
  	 }

 get fE(){ return this.formEvent.controls;}
 get fC(){ return this.formCalendar.controls;}

  ngOnInit() {
  	this.getEventos();
    this.getGrupos();
    this.getMiembros();
  }

  delEvento(id){
  	this.event_select = id;
  	this.delAlert.show();
  }

  deleteActividad(){
    this._gS.deleteActividad(this.event_select).subscribe(res=>{
      if(res.code == 200){
        this.titulo = 'Actividad Eliminada';
        this.texto = 'La actividad fue eliminada exitosamente';
        this.tipo = 'success';
        this.getEventos();
        setTimeout(()=>{
        this.actividadAlert.show();
        },100);
        
      }else{
        this.titulo = 'Error';
        this.texto = 'La actividad no se puede eliminar puesto que esta asociada a 1 o mas grupos en un evento';
        this.tipo = 'error';
        setTimeout(()=>{
        this.actividadAlert.show();
        },100);
      }
    },error=>{
         this.titulo = 'Internal Error';
        this.texto = 'ocurrio un error interno, por favor intentelo mas tarde, si el error persiste comuniquese con el departamento de sistemas';
        this.tipo = 'error';
        setTimeout(()=>{
        this.actividadAlert.show();
        },100);
      console.log(error);
    });
  }

  confirmDelEvent(){
  	console.log("delete");
  }

  getEventos(){
  	this._gS.getEventos().subscribe(res=>{
  		if(res.code == 200){
  			this.eventos = [];
  			for (var i = 0; i < res.data.length; ++i) {
  				this.eventos.push(res.data[i]);
  			}
  			this.loading = false;
  		}
  	},error=>{
  		console.log(error);
  	});
  }


  getGrupos(){
    this._gS.getGrupos(this.datos.marca, this.datos.sede).subscribe(res=>{
      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.grupos.push(res.data[i]);
        }
      }
    },error=>{
      console.log(error);
    });
  }

  getMiembros(){
    console.log(this.datos);
    this._gS.getMiembros(this.datos.marca, this.datos.sede).subscribe(res=>{
      console.log(res);
      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.miembros.push(res.data[i]);
        }
      }
    },error=>{
      console.log(error);
    });
  }

  setEvento(){

  	this.submited = true;


  	if(this.formEvent.valid){
  	let datos = {
  		nombre : this.fE.nombre.value,
  		sigla : this.fE.siglas.value,
  		color : this.color
  	}

  	this._gS.setEvento(datos).subscribe(res=>{
  		if(res.code == 200){
  			console.log(res.message);
  			this.submited = false;
  			this.formEvent.reset();
  			this.successAlert.show();
  			this.getEventos();
  		}else{
        this.errorAlert.show();
      }	
  	},error=>{
      this.errorAlert.show();
  		console.log(error);
  	});
  }
}

setEventoCalendario(){

  this.submitedC = true;

  if(this.formCalendar.valid){
     let datos = {
       localidad : this.fC.localidad.value,
       ciudad : this.fC.ciudad.value,
       fecha_inicio : this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day,
       fecha_fin : this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day,
       eventos_id : this.fC.evento_id.value,
       grupos_id : this.fC.grupo_id.value,
       destacado : this.fC.destacado.value
     }
     this._gS.setEventoCalendario(datos).subscribe(res=>{
       if(res.code == 200){
         this.asignacionAlert.show();
         this.formCalendar.reset();
         this.submitedC = false;
       }else{
         this.errorAlert.show();
       }
     },error=>{
       this.errorAlert.show();
       console.log(error);
     });
  }

}

openLg(content) {
    this.modalService.open(content, { size: 'lg' });
  }

// ************ Calendar Functions ******************

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }
}
