import { Component, OnInit, ViewChild } from '@angular/core';
import { GruposService } from '../../servicios/grupos.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbDateStruct, NgbCalendar, NgbModal, NgbDate} from '@ng-bootstrap/ng-bootstrap';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';

const now = new Date();

declare var $ : any;

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit {

  @ViewChild('asignacion') asignacionAlert : SwalComponent;
  @ViewChild('error') errorAlert : SwalComponent;
  @ViewChild('errorEdit') errorEditAlert : SwalComponent;
  @ViewChild('delete') Delete : SwalComponent;
  
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  mes_actual : number;
  ano_actual : number;
  dia_actual : number;
  mes_sistema : number;
  dias : any;


  meses : Array<string>;
  dias_mes : Array<any>;
  dias_mes_jac_tulua : Array<any>;

  dias_mes_kia_palmira : Array<any>;
  dias_mes_jac_palmira : Array<any>;

  dias_mes_kia_buga : Array<any>;
  dias_mes_jac_buga : Array<any>;

  grupos : Array<any>;
  allGroups : Array<any>;
  eventos : Array<any>;
  miembros : Array<any>;

  fecha_procesada : string;

  loading : boolean = false;
  loadingEvent : boolean = false;
  submitedC : boolean = false;

  formCalendar : FormGroup;
  formCalendarEdit : FormGroup;

  datos : any;

  id_actual : string;

  constructor(
  	private _gS : GruposService,
    private calendar: NgbCalendar,
    private fb : FormBuilder,
    private modalService: NgbModal
  	) { 
  	this.meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  	this.mes_actual = now.getMonth();
  	this.ano_actual = now.getFullYear();

  	
  	this.fecha_procesada = '';
    this.id_actual = '';

    this.formCalendar = this.fb.group({
      localidad : ['', Validators.required],
      ciudad : ['', Validators.required],
      grupo_id : [ , Validators.required],
      evento_id : [, Validators.required],
      destacado : [false]      
    });

    this.formCalendarEdit = this.fb.group({
      localidad : ['', Validators.required],
      ciudad : ['', Validators.required],
      grupo_id : [ , Validators.required],
      evento_id : [, Validators.required],
      destacado : [false]      
    });

    this.dias_mes = [];
    this.eventos = [];
    this.grupos = [];
    this.miembros = [];
    this.allGroups = [];

    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 3);

   this.datos = JSON.parse(localStorage.getItem('data')); 

   this.dia_actual = now.getDate();
   this.mes_sistema = now.getMonth();

  }

  ngOnInit() {
  	this.dias = new Date(this.ano_actual, this.mes_actual+1,0).getDate();
  	this.calculateDayOfMonth();
    this.getAllGrupos();
    this.getEventos();
    this.getGrupos();
    this.getMiembros();

    console.log(this.dia_actual);
    console.log(this.mes_actual);
    console.log(this.mes_sistema);
  }

  get fC(){ return this.formCalendar.controls;}

  get fE(){ return this.formCalendarEdit.controls;}

  nextMonth(){
  	if(this.mes_actual<11){
  	this.mes_actual++;
  	this.dias = new Date(this.ano_actual, this.mes_actual+1,0).getDate();
  	this.calculateDayOfMonth();
    this.getGruposAntiguos();
  	}
  }

  previousMonth(){
  	if(this.mes_actual>0){
  	this.mes_actual--;	
  	this.dias = new Date(this.ano_actual, this.mes_actual+1,0).getDate();	
  	this.calculateDayOfMonth();
    this.getGruposAntiguos();
  	}
  }

  calculateDayOfMonth(){
  	this.loading = true;
  	this.dias_mes = [];
  	for (var i = 0; i < this.dias; ++i) {
  		// this.fecha_procesada = this.ano_actual+'-'+(this.mes_actual)+'-'+(i+1);
  		let fecha = this.ano_actual+'-'+(this.mes_actual+1)+'-'+(i+1);
  		this.cargarDatos(fecha, i+1);
  	}
  }

  getEventos(){
    this._gS.getEventos().subscribe(res=>{
      if(res.code == 200){
        this.eventos = [];
        for (var i = 0; i < res.data.length; ++i) {
          this.eventos.push(res.data[i]);
        }
        this.loading = false;
      }
    },error=>{
      console.log(error);
    });
  }

  getMiembros(){
    console.log(this.datos);
    this._gS.getMiembros(this.datos.marca, this.datos.sede).subscribe(res=>{
      console.log(res);
      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.miembros.push(res.data[i]);
        }
      }
    },error=>{
      console.log(error);
    });
  }

  getAllGrupos(){
    this._gS.getAllGrupos().subscribe(res=>{
      console.log(res);
      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.allGroups.push(res.data[i]);
        }
      }
    },error=>{
      console.log(error);
    });
  }


  getGrupos(){
    this._gS.getGrupos(this.datos.marca, this.datos.sede).subscribe(res=>{
      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.grupos.push(res.data[i]);
        }
      }
    },error=>{
      console.log(error);
    });
  }

  getGruposAntiguos(){
    this.allGroups = [];
    this._gS.getGruposAntiguos(this.mes_actual+1).subscribe(res=>{
      if(res.code == 200){
        for (var i = 0; i < res.data.length; ++i) {
          this.allGroups.push(res.data[i]);
        }
      }
    },error=>{
      console.log(error);
    });
  }


  cargarDatos(fecha, dia){
  	this._gS.getCalendario(fecha).subscribe(res=>{
  		if(res.code == 200){

  		this.dias_mes.push({
  			dia : dia,
  			datos : res.data
  		});
  		}

  		if(dia == this.dias-1){
  			this.loading = false;
  		}
  	},error=>{
  		console.log(error);
  	});
  }

  getEventosCalendario(){
    this.loadingEvent = true;
    this._gS.getCalendarioEvento(this.id_actual).subscribe(res=>{
      this.loadingEvent = false;
      if(res.code == 200){
           this.fE.evento_id.setValue(res.data[0].eventos_id);
           this.fE.grupo_id.setValue(res.data[0].grupos_id);
           this.fE.destacado.setValue(!!parseInt(res.data[0].destacado));
           this.fE.ciudad.setValue(res.data[0].ciudad);
           this.fE.localidad.setValue(res.data[0].localidad);

           $('#editable').show('slide',500);

           this.fromDate.day = parseInt(res.data[0].diaI);
           this.fromDate.month = parseInt(res.data[0].mesI);
           this.fromDate.year = this.ano_actual;

           this.toDate.day = parseInt(res.data[0].diaF);
           this.toDate.month = parseInt(res.data[0].mesF);
           this.toDate.year = this.ano_actual;
           
      }
    },error=>{
      this.loadingEvent = false;
      console.log(error);
    });
  }

setEventoCalendario(){

  this.submitedC = true;

  if(!this.toDate){
    this.toDate = this.fromDate;
  }

  if(this.formCalendar.valid){
     let datos = {
       localidad : this.fC.localidad.value,
       ciudad : this.fC.ciudad.value,
       fecha_inicio : this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day,
       fecha_fin : this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day,
       eventos_id : this.fC.evento_id.value,
       grupos_id : this.fC.grupo_id.value,
       destacado : this.fC.destacado.value
     }

     this._gS.setEventoCalendario(datos).subscribe(res=>{
       if(res.code == 200){
         this.asignacionAlert.show();
         this.formCalendar.reset();
         this.submitedC = false;
         this.buscarDato(this.dias_mes,'dia',this.fromDate.day,this.toDate.day);
       }else{
         this.errorAlert.show();
       }
     },error=>{
       this.errorAlert.show();
       console.log(error);
     });
  }

}


  buscarDato(arreglo : Array<any>, nombre : string, dia_ini, dia_fin){
    for (var i = 0; i < arreglo.length; ++i) {
      if(arreglo[i][nombre] >= dia_ini && arreglo[i][nombre] <= dia_fin){
        console.log(arreglo[i]);
        let fecha = this.ano_actual+'-'+(this.mes_actual+1)+'-'+(arreglo[i][nombre]);
        let dia = arreglo[i][nombre];
        this.insertData(fecha, i);
      }
    }
  }

  menuOptions(id, content){
    this.id_actual = id;
    this.openMd(content);
  }

  showQuestion(){
    this.Delete.show();
  }

  showEditMenu(){
    this.getEventosCalendario();
  }


  openMd(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  insertData(fecha, index){
      this._gS.getCalendario(fecha).subscribe(res=>{
          if(res.code == 200){
            if(res.data.length > 0){
              this.dias_mes[index].datos = res.data;
            }
          }
        },error=>{
          console.log(error);
        });
  }

  openLg(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  closeModals(){
    this.modalService.dismissAll();
  }

  deleteEvento(){
    this._gS.deleteEvento(this.id_actual).subscribe(res=>{
      if(res.code == 200){
        console.log('success....');
        this.closeModals();
        this.calculateDayOfMonth();
      }
    },error=>{
      console.log(error);
    })
  }


  editEventoCalendario(){


     if(this.formCalendarEdit.valid){
     
     let datos = {
      id : this.id_actual,
      localidad :  this.fE.localidad.value,
      ciudad : this.fE.ciudad.value,
      eventos_id : this.fE.evento_id.value,
      grupos_id : this.fE.grupo_id.value,
      destacado : this.fE.destacado.value,
      fecha_ini : this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day,
      fecha_fin : this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day
    }

    this._gS.updateEventoCalendario(datos).subscribe(res=>{
      console.log(res);
      if(res.code == 200){
          this.calculateDayOfMonth();
          this.modalService.dismissAll();
        }else{
          this.errorEditAlert.show();
        }
    },error=>{  
      console.log(error);
    });
    }

  }

  // ************ Calendar Functions ******************


  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}

